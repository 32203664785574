import React, {
  ComponentType,
  ElementType,
  forwardRef,
  FunctionComponent,
  // ComponentProps, TODO: uncomment and fix the components who calls Autocomplete
  ReactNode,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Divider } from '@mui/material';
import Downshift, {
  ControllerStateAndHelpers,
  DownshiftProps,
} from 'downshift';
import { Field } from 'formik';
import deburr from 'lodash/deburr';
import memoize from 'lodash/memoize';
import omit from 'lodash/omit';
import { makeStyles } from 'tss-react/mui';

import { DatacyContext, useDatacyName } from '@docavenue/chat';

import Chip from '../../atoms/Chip';
import UnfoldInputAdornment from '../../molecules/UnfoldInputAdornment';
import LineDivider from '../CreateAppointmentForm/LineDivider';
import DefaultInputComponent from './InputComponent';
import DefaultItemComponent from './ItemComponent';
import DefaultListComponent from './ListComponent';
import DefaultListSkeletonComponent from './ListSkeletonComponent';

export type DIVIDER<T extends ComponentType<any>> = {
  isAutocompleteDivider: boolean;
  component: ElementType<T>;
  name?: string;
  dividerLabelKey?: string;
};

export type BasicDivider = DIVIDER<typeof Divider>;

export type NoPreference = {
  id: null;
  value: any;
  name?: string;
  firstName?: string;
};

export const AUTOCOMPLETE_DIVIDER = {
  isAutocompleteDivider: true,
  component: LineDivider,
};

const isDivider = <T extends {}>(
  item: T | DIVIDER<any>,
): item is DIVIDER<any> =>
  Boolean(
    typeof item === 'object' &&
      'isAutocompleteDivider' in item &&
      'component' in item,
  );

/**
 * Return an object where keys are item.id if they exist, and values are groups where they belong
 * The group here is the name of the Divider component surrounding them.
 */
export const getItemsWithTheirGroups = <T,>(
  items: Array<T | DIVIDER<any> | NoPreference>,
): Record<string, Array<string>> => {
  let currentGroup: undefined | string;
  const itemsWithTheirGroups: Record<string, Array<string>> = {};
  items.forEach(item => {
    if (item && isDivider(item)) {
      const componentName =
        (typeof item.component === 'function' ||
          typeof item.component === 'object') &&
        'name' in item.component
          ? (item.component as React.ComponentType<any>).name
          : '';
      if (componentName) currentGroup = componentName;
    } else if (currentGroup) {
      if (typeof item === 'object' && item && 'id' in item && item.id) {
        itemsWithTheirGroups[item.id] = itemsWithTheirGroups[item.id]
          ? [currentGroup, ...itemsWithTheirGroups[item.id]]
          : [currentGroup];
      }
    }
  });
  return itemsWithTheirGroups;
};

// eslint-disable-next-line unused-imports/no-unused-vars
const useStyles = makeStyles<{
  isMulti: boolean;
  isWarningHelperText: boolean;
}>()((theme: any, { isMulti, isWarningHelperText }) => ({
  autocompleteRoot: {
    position: 'relative',
    '& * .MuiInputBase-root > input': {
      fontSize: theme.size.font.md,
      width: isMulti ? '100%' : '87%',
    },
    '& * .MuiInputBase-root > .MuiSvgIcon-root': {
      position: 'absolute',
      right: 5,
    },
    '& * .MuiInputAdornment-root > .MuiSvgIcon-root': {
      position: 'absolute',
      top: theme.spacing(1),
      right: 5,
    },
    '& * .MuiChip-root > .MuiSvgIcon-root': {
      position: 'absolute',
      top: 2,
      right: 5,
    },
    '& * .MuiChip-deletable > .MuiSvgIcon-root': {
      position: 'initial',
      right: 'unset',
    },
    '& * .MuiFormHelperText-root': {
      color: isWarningHelperText
        ? theme.palette.warning.main
        : theme.palette.destructive.main,
    },
  },
  fullWidth: {
    width: '100%',
  },
}));

// type OnChange<T> = (item: (T | T[]) | null | NoPreference) => void;
// type OnChange<T> = (item: T | null | NoPreference) => void;
// type OnChangeMulti<T> = (items: (T | NoPreference)[] | null) => void;

type Props<
  T = any,
  Input extends FunctionComponent<any> = FunctionComponent<any>,
  Item extends FunctionComponent<any> = FunctionComponent<any>,
  List extends FunctionComponent<any> = FunctionComponent<any>,
  Skeleton extends FunctionComponent<any> = FunctionComponent<any>
> = {
  ChipComponent?: ComponentType<any>;
  datacy?: string;
  endAdornment?: React.ReactNode;
  formatInputForComparaison?: (inputValue: string) => string;
  getFilterValue?: (item: T) => string | null;
  getOptionLabel?: (item: T) => string | null | undefined;
  gridkey?: string;
  InputComponent?: Input;
  ListSkeletonComponent?: Skeleton;
  inputProps?: any;
  inputRef?: RefObject<HTMLInputElement>;
  inputValue?: string;
  isLoading?: boolean;
  isMenuOpen?: boolean;
  isMulti?: boolean;
  isNoFilter?: boolean;
  ItemComponent?: Item;
  itemProps?: Record<string, any>;
  items: Array<T | DIVIDER<any> | NoPreference>;
  listClassName?: string;
  ListComponent?: List;
  listProps?: Omit<Record<string, any>, 'closeMenu' | 'children' | 'isOpen'>;
  onChange: (value: T | T[] | null) => void;
  // onChange: OnChange<T> | OnChangeMulti<T>;
  optionKey?: string;
  required?: boolean;
  selected?: (T | T[]) | null | NoPreference;
  setInputValue?: (inputValue: string) => void;
  suggestionListHeader?: ReactNode;
  hideError?: boolean;
  warningText?: string;
  isUseMemoizeList?: boolean;
  showStartAdornment?: boolean;
  hasEmptySuggestions?: boolean;
  disabled?: boolean;
} & Omit<DownshiftProps<T>, 'onChange'>;

const defaultArray = [];

const formatText = text => deburr(text).toLowerCase();

const isPlural = <T extends {}>(items: T[], index: number) =>
  items[index + 1] &&
  items[index + 2] &&
  !isDivider(items[index + 1]) &&
  !isDivider(items[index + 2]);

const renderItemsMemoized = memoize(
  (renderedItems, suggestionListHeader, _, isOpen) => [
    ...(isOpen && suggestionListHeader ? [suggestionListHeader] : []),
    ...renderedItems,
  ],
  (renderedItems, _, searchTerm, isOpen) =>
    `${renderedItems.map(i => i.key).join('-')}-${searchTerm}-${
      isOpen ? 'open' : 'close'
    }`,
);

const Autocomplete = forwardRef(
  <
    T extends {},
    Input extends typeof Field = typeof DefaultInputComponent,
    Item extends FunctionComponent<any> = typeof DefaultItemComponent,
    List extends FunctionComponent<any> = typeof DefaultListComponent,
    Skeleton extends FunctionComponent<
      any
    > = typeof DefaultListSkeletonComponent
  >(
    props: Props<T, Input, Item, List, Skeleton>,
    ref: React.Ref<ControllerStateAndHelpers<T>>,
  ) => {
    const {
      ChipComponent = Chip,
      datacy,
      disabled,
      endAdornment: endAdornmentJsx,
      formatInputForComparaison = formatText,
      getOptionLabel = (option: { name?: string }) => option?.name,
      getFilterValue = getOptionLabel,
      gridkey,
      InputComponent = DefaultInputComponent,
      inputProps,
      inputRef: parentInputRef = null,
      inputValue: inputValueProps,
      isLoading = false,
      isMenuOpen,
      isMulti = false,
      isNoFilter = false,
      ItemComponent = DefaultItemComponent,
      itemProps,
      items = [],
      listClassName,
      ListComponent = DefaultListComponent,
      listProps = {},
      onChange,
      optionKey = 'id',
      required,
      selected = isMulti ? [] : null,
      setInputValue: setInputValueProps,
      suggestionListHeader,
      hideError,
      warningText,
      isUseMemoizeList = false,
      ListSkeletonComponent = DefaultListSkeletonComponent,
      showStartAdornment = false,
      hasEmptySuggestions = false,
      ...rest
    } = props;

    const { classes, cx } = useStyles({
      isMulti,
      isWarningHelperText: !!inputProps?.isWarningHelperText,
    });
    const [inputValueState, setInputValueState] = useState('');
    const inputValue: string = inputValueProps || inputValueState;
    const setInputValue: Function = setInputValueProps || setInputValueState;
    const defaultInputRef = useRef(null);
    const inputRef = parentInputRef ?? defaultInputRef;

    let formatedDatacy = 'autocomplete';
    if (datacy) {
      formatedDatacy = `${datacy}_autocomplete`;
    } else if (inputProps?.field?.name) {
      formatedDatacy = `${inputProps.field?.name}_autocomplete`;
    }

    const datacyName = useDatacyName(formatedDatacy);

    useEffect(
      () => () =>
        // @ts-ignore
        renderItemsMemoized?.cache?.clear(),
      [],
    );

    const handleChange = item => {
      if (inputProps?.field?.onSelect) {
        inputProps.field.onSelect(inputProps.field);
      }
      if (isMulti && Array.isArray(selected)) {
        let newSelectedItems = [...selected];
        if (newSelectedItems.indexOf(item) === -1 && item) {
          newSelectedItems = [...newSelectedItems, item];
        }
        onChange(newSelectedItems);
        setInputValue('');
        if (inputRef?.current) {
          inputRef.current.blur();
        }
      } else {
        setInputValue(item ? getOptionLabel(item) : '');
        onChange(item);
      }
      if (inputRef.current) {
        inputRef.current.blur();
      }
    };

    const handleDelete = item => () => {
      if (Array.isArray(selected)) {
        const newSelectedItems = [...selected];
        newSelectedItems.splice(
          newSelectedItems.findIndex(i => item[optionKey] === i[optionKey]),
          1,
        );
        onChange(newSelectedItems);
        if (inputProps?.form && inputProps.field.name) {
          inputProps.form.setTouched({ [inputProps.field.name]: true });
        }
      }
    };

    return (
      <DatacyContext.Provider value={datacyName}>
        <Downshift
          // @ts-ignore
          ref={ref}
          onChange={handleChange}
          itemToString={item => (!isMulti && item ? item.value : '')}
          selectedItem={isMulti ? selected : null}
          inputValue={inputValue}
          isOpen={isMenuOpen}
          {...rest}
        >
          {(downProps: ControllerStateAndHelpers<T | DIVIDER<any>>) => {
            const {
              getInputProps,
              getItemProps,
              getLabelProps,
              getMenuProps,
              isOpen,
              inputValue: downshiftInputValue,
              highlightedIndex,
              selectedItem,
              openMenu,
              closeMenu,
            } = downProps;
            const endAdornment =
              endAdornmentJsx !== undefined ? (
                endAdornmentJsx
              ) : (
                <UnfoldInputAdornment
                  setIsOpen={() => {
                    if (!disabled) {
                      if (isOpen) {
                        closeMenu();
                      } else {
                        openMenu();
                        if (inputRef.current) inputRef.current.focus();
                      }
                    }
                  }}
                  isOpen={isOpen}
                  disabled={Boolean(inputProps?.disabled)}
                />
              );
            const formattedInput = formatInputForComparaison(
              downshiftInputValue ?? '',
            );

            const simpleFilter = item =>
              isNoFilter ||
              !downshiftInputValue ||
              selected ||
              isDivider(item) ||
              formatInputForComparaison(
                (item && getFilterValue(item)) || '',
              ).includes(formattedInput);
            const multipleFilter = item =>
              (!downshiftInputValue ||
                formatInputForComparaison(
                  (item && getFilterValue(item)) || '',
                ).includes(formattedInput)) &&
              Array.isArray(selected) &&
              selected.findIndex(
                elem => elem[optionKey] === item[optionKey],
              ) === -1;
            const filterMode = isMulti ? multipleFilter : simpleFilter;
            const showAdornment =
              isMulti && Array.isArray(selected) && selected.length > 0;

            const filteredItem = isOpen ? items.filter(filterMode) : null;

            const numberOfDividers = items.filter(
              (item: any) => item?.isAutocompleteDivider!,
            ).length;

            const downshiftInputProps = getInputProps({
              onFocus: openMenu,
              disabled,
              onKeyDown: event => {
                //  enter select the higlight or the first ?
                if (event.key === 'Enter') {
                  // Prevent Downshift's default 'Enter' behavior.
                  // eslint-disable-next-line no-param-reassign
                  event.nativeEvent.preventDownshiftDefault = true;

                  // your handler code
                  if (filteredItem) {
                    const filteredItemWithoutDivider = filteredItem.filter(
                      item => !isDivider(item),
                    );
                    if (filteredItemWithoutDivider.length) {
                      handleChange(filteredItem[highlightedIndex || 0]);
                      closeMenu();
                    }
                  }
                }
              },
            });

            const onFocus = event => {
              if (inputProps?.field?.onFocus) {
                inputProps.field.onFocus(inputProps.field);
              }
              downshiftInputProps.onFocus();
              if (selected) {
                event.target.select();
              }
            };

            let newInputProps = inputProps;
            if (isMulti && Array.isArray(selected) && selected.length > 0) {
              newInputProps = omit(newInputProps, 'placeholder');
            }

            const renderedItems =
              !isLoading && isOpen && filteredItem
                ? filteredItem
                    .map((item, index) => {
                      if (isDivider(item)) {
                        const itemIndex = index;
                        const dividerItem = item as DIVIDER<any>;
                        const dividerName = dividerItem?.name;
                        const DividerComponent = dividerItem.component;
                        const labelKey = dividerItem.dividerLabelKey;
                        return (
                          <DividerComponent
                            numberOfDividers={numberOfDividers}
                            prevIndexItem={filteredItem[index - 1]}
                            nextIndexItem={filteredItem[index + 1]}
                            length={filteredItem.length}
                            isPlural={isPlural(filteredItem, index)}
                            name={dividerName}
                            labelKey={labelKey}
                            key={`divider-${itemIndex}`}
                          />
                        );
                      }
                      const autocompleteItem = item as T;
                      const downshiftItemProps = getItemProps({
                        key: item[optionKey],
                        index,
                        item: autocompleteItem,
                        selected:
                          (selectedItem &&
                            selectedItem[optionKey] === item[optionKey]) ??
                          undefined,
                      });
                      return (
                        <ItemComponent
                          datacy={getOptionLabel(autocompleteItem)}
                          key={item[optionKey]}
                          highlighted={highlightedIndex === index}
                          {...downshiftItemProps}
                          searchTerm={formatInputForComparaison(
                            downshiftInputValue ?? '',
                          )}
                          {...itemProps}
                          item={item}
                        >
                          {getOptionLabel(autocompleteItem)}
                        </ItemComponent>
                      );
                    })
                    // use in case empty list except null divider
                    .filter(e => e)
                : null;

            const hasSuggestionsHeader = hasEmptySuggestions
              ? renderedItems && renderedItems.length > 0
              : true;

            return (
              <div
                className={cx(classes.autocompleteRoot, listClassName)}
                gridkey={gridkey}
              >
                <InputComponent
                  inputRef={inputRef}
                  required={required}
                  className={classes.fullWidth}
                  {...newInputProps}
                  InputLabelProps={{
                    ...getLabelProps(),
                    ...(inputProps?.InputLabelProps || {}),
                  }}
                  inputProps={{
                    datacy: datacyName,
                    ...(inputProps?.inputProps || {}),
                  }}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{
                    endAdornment,
                    ...downshiftInputProps,
                    ...(inputProps?.InputProps || {}),
                    onFocus,
                    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                      setInputValue(event.target?.value);
                      if (!isMulti) {
                        // WARNING : should be onInputChange ?
                        if (selected || selectedItem) {
                          if (selectedItem) {
                            // eslint-disable-next-line no-console
                            console.warn(
                              'Autocomplete: selectedItem is a deprecated props, prefer selected',
                            );
                          }
                          onChange(null);
                        }
                      }
                      if (downshiftInputProps.onChange) {
                        downshiftInputProps.onChange(event);
                      }
                    },
                    startAdornment: showStartAdornment
                      ? inputProps?.InputProps?.startAdornment
                      : showAdornment &&
                        Array.isArray(selected) &&
                        selected.map(item => (
                          <ChipComponent
                            key={item[optionKey]}
                            tabIndex={-1}
                            label={getOptionLabel(item)}
                            onDelete={
                              inputProps.disabled
                                ? undefined
                                : handleDelete(item)
                            }
                            datacy={datacyName ?? ''}
                            item={item}
                          />
                        )),
                    autoComplete: 'new-password', // hack to disable autocomplete from browsers and password managers
                  }}
                  hideError={hideError}
                  warningText={warningText}
                />
                <ListComponent
                  {...getMenuProps()}
                  {...listProps}
                  closeMenu={closeMenu}
                  isLoading={isLoading}
                  isOpen={isOpen}
                >
                  {isUseMemoizeList &&
                    isOpen &&
                    renderItemsMemoized(
                      renderedItems || defaultArray,
                      suggestionListHeader,
                      // @ts-ignore
                      itemProps?.searchTerm ??
                        formatInputForComparaison(downshiftInputValue ?? ''),
                      isOpen,
                    )}

                  {!isUseMemoizeList &&
                    isOpen &&
                    hasSuggestionsHeader &&
                    suggestionListHeader}

                  {!isUseMemoizeList &&
                    isOpen &&
                    (renderedItems || defaultArray)}
                  {isLoading && isOpen && <ListSkeletonComponent />}
                </ListComponent>
              </div>
            );
          }}
        </Downshift>
      </DatacyContext.Provider>
    );
  },
) as <
  T,
  Input extends typeof Field = typeof DefaultInputComponent,
  Item extends FunctionComponent<any> = typeof DefaultItemComponent,
  List extends FunctionComponent<any> = typeof DefaultListComponent,
  Skeleton extends FunctionComponent<any> = typeof DefaultListSkeletonComponent
>(
  props: Props<T, Input, Item, List, Skeleton> &
    React.RefAttributes<ControllerStateAndHelpers<T>>,
) => React.ReactElement;

export default Autocomplete;
